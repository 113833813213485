import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import t from '../lang';
import LanguageSwitch from '../components/LanguageSwitch';

const NavMobile = styled.nav`
  margin: 15px 0 0 50px;
`;

const NavMobileList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavMobileItem = styled.li`
  font-size: 1.8rem;
  margin-bottom: 1.0rem;

  & button {
    display: block;
    margin: 0;
  }
`;

const MainNavMobileItem = styled(NavMobileItem)`
  font-size: 2.5rem;
`;

const NavMobileLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const NavigationMobile = (props) => {
  return (
    <NavMobile>
      <NavMobileList>
        <MainNavMobileItem>
          <NavMobileLink to="/news" onClick={props.toggleHandler}>{t('news')}</NavMobileLink>
        </MainNavMobileItem>
        <MainNavMobileItem>
          <NavMobileLink to="/architecture" onClick={props.toggleHandler}>{t('architecture')}</NavMobileLink>
        </MainNavMobileItem>
        <NavMobileItem>
          <NavMobileLink to="/architecture/selection" onClick={props.toggleHandler}>{t('selection')}</NavMobileLink>
        </NavMobileItem>
        <NavMobileItem>
          <NavMobileLink to="/architecture/all" onClick={props.toggleHandler}>{t('all')}</NavMobileLink>
        </NavMobileItem>
        <MainNavMobileItem>
          <NavMobileLink to="/urban-design" onClick={props.toggleHandler}>{t('urban-design')}</NavMobileLink>
        </MainNavMobileItem>
        <NavMobileItem>
          <NavMobileLink to="/urban-design/selection" onClick={props.toggleHandler}>{t('selection')}</NavMobileLink>
        </NavMobileItem>
        <NavMobileItem>
          <NavMobileLink to="/urban-design/all" onClick={props.toggleHandler}>{t('all')}</NavMobileLink>
        </NavMobileItem>
        <MainNavMobileItem>
          <NavMobileLink to="/office" onClick={props.toggleHandler}>{t('office')}</NavMobileLink>
        </MainNavMobileItem>
        <NavMobileItem>
          <NavMobileLink to="/office/profile" onClick={props.toggleHandler}>{t('profile')}</NavMobileLink>
        </NavMobileItem>
        <NavMobileItem>
          <NavMobileLink to="/office/people" onClick={props.toggleHandler}>{t('people')}</NavMobileLink>
        </NavMobileItem>
        <NavMobileItem>
          <NavMobileLink to="/office/references" onClick={props.toggleHandler}>{t('references')}</NavMobileLink>
        </NavMobileItem>
        <MainNavMobileItem>
          <NavMobileLink to="/contact" onClick={props.toggleHandler}>{t('contact')}</NavMobileLink>
        </MainNavMobileItem>
        <MainNavMobileItem>
          <NavMobileLink to="/privacy-policy" onClick={props.toggleHandler}>{t('privacy-policy')}</NavMobileLink>
        </MainNavMobileItem>
        <MainNavMobileItem>
          <NavMobileLink to="/imprint" onClick={props.toggleHandler}>{t('imprint')}</NavMobileLink>
        </MainNavMobileItem>
        <NavMobileItem>
          <LanguageSwitch toggleHandler={props.languageHandler} currentLanguage={props.currentLanguage}/>
        </NavMobileItem>
      </NavMobileList>
    </NavMobile>
  );
};

export default NavigationMobile;