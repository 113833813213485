const lang = {
  'news': {
    'de': 'Aktuelles',
    'en': 'News'
  },
  'architecture': {
    'de': 'Architektur',
    'en': 'Architecture'
  },
  'urban-design': {
    'de': 'Städtebau',
    'en': 'Urban Design'
  },
  'office': {
    'de': 'Büro',
    'en': 'Office'
  },
  'contact': {
    'de': 'Kontakt',
    'en': 'Contact'
  },
  'selection': {
    'de': 'Auswahl',
    'en': 'Selection'
  },
  'all': {
    'de': 'Alles',
    'en': 'All'
  },
  'profile': {
    'de': 'Profil',
    'en': 'Profile'
  },
  'people': {
    'de': 'Menschen',
    'en': 'People'
  },
  'references': {
    'de': 'Referenzen',
    'en': 'References'
  },
  'philosophy': {
    'de': 'Philosophie',
    'en': 'Philosophy'
  },
  'structure': {
    'de': 'Struktur',
    'en': 'Structure'
  },
  'service': {
    'de': 'Leistungsbild',
    'en': 'Service'
  },
  'equipment': {
    'de': 'Ausstattung',
    'en': 'Equipment'
  },
  'team': {
    'de': 'Team',
    'en': 'Team'
  },
  'management': {
    'de': 'Geschäftsführung',
    'en': 'Management'
  },
  'engagement': {
    'de': 'Engagement',
    'en': 'Engagement'
  },
  'career': {
    'de': 'Karriere',
    'en': 'Career'
  },
  'publications': {
    'de': 'Veröffentlichungen',
    'en': 'Publications'
  },
  'awards': {
    'de': 'Preise',
    'en': 'Awards'
  },
  'lectures': {
    'de': 'Vorträge',
    'en': 'Lectures'
  },
  'exhibitions': {
    'de': 'Ausstellungen',
    'en': 'Exhibitions'
  },
  'contests': {
    'de': 'Wettbewerbe',
    'en': 'Contests'
  },
  'privacy-policy': {
    'de': 'Datenschutz',
    'en': 'Privacy Policy'
  },
  'imprint': {
    'de': 'Impressum',
    'en': 'Imprint'
  },
  'project_description': {
    'de': 'Projektbeschreibung',
    'en': 'Project Description'
  },
  'page_not_found': {
    'de': 'Die Seite wurde nicht gefunden',
    'en': 'Page not found'
  },
  'location': {
    'de': 'Ort',
    'en': 'Location'
  },
  'year' :{
    'de': 'Jahr',
    'en': 'Year'
  },
  'contest': {
    'de': 'Wettbewerb',
    'en': 'Contest'
  },
  'client': {
    'de': 'Bauherr',
    'en': 'Client'
  },
  'size': {
    'de': 'Größe',
    'en': 'Size'
  },
  'architects': {
    'de': 'Architekten',
    'en': 'Architects'
  },
  'planning_team': {
    'de': 'Planungsteam',
    'en': 'Planning Team'
  },
  'staff': {
    'de': 'Mitarbeiter',
    'en': 'Project Team'
  },
  'project': {
    'de': 'Projekt',
    'en': 'Project'
  },
  'status': {
    'de': 'Status',
    'en': 'Status'
  },
  'usage': {
    'de': 'Nutzung',
    'en': 'Usage'
  },
  'public': {
    'de': 'Öffentlich',
    'en': 'Public'
  },
  'commercial': {
    'de': 'Gewerbe',
    'en': 'Commercial'
  },
  'residential_s': {
    'de': 'Wohnen S',
    'en': 'Residential S'
  },
  'residential_m': {
    'de': 'Wohnen M',
    'en': 'Residential M'
  },
  'residential_l': {
    'de': 'Wohnen L',
    'en': 'Residential L'
  },
  'residential_x': {
    'de': 'Wohnen X',
    'en': 'Residential X'
  },
  'residential_xl': {
    'de': 'Wohnen XL',
    'en': 'Residential XL'
  },
  'interaction': {
    'de': 'Interaktion',
    'en': 'Interaction'
  },
  'study': {
    'de': 'Studie',
    'en': 'Study'
  },
  'job': {
    'de': 'Beauftragt',
    'en': 'Instructed'
  },
  'finished': {
    'de': 'Abgeschlossen',
    'en': 'Finished'
  },
  'built': {
    'de': 'Gebaut',
    'en': 'Built'
  },
  'to_project': {
    'de': 'zum Projekt',
    'en': 'goto Project'
  },
  'more_details': {
    'de': 'mehr Details',
    'en': 'more details'
  },
  'project_not_found': {
    'de': 'Das Projekt wurde nicht gefunden',
    'en': 'This project was not found'
  }
};

export default function t(item, property = null) {
  if (typeof item === 'string') {
    return lang[item][window.currentLanguage] || lang[item]['de'];
  } else if (typeof item === 'object' && property) {
    return item[`${property}_${window.currentLanguage}`] ? item[`${property}_${window.currentLanguage}`] : item[`${property}`];
  } 
}