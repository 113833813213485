import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
`;

const MobileCloseButton = (props) => {
  return (
    <Button onClick={props.toggleHandler}>
      <svg width="24px" height="25px" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
        <g id="mobile-menu-close" fill="#000000">
          <g id="mobile-menu-icon">
            <rect id="Rectangle" transform="translate(11.667262, 12.667262) rotate(-45.000000) translate(-11.667262, -12.667262) " x="-2.33273811" y="10.1672619" width="28" height="5"></rect>
            <rect id="Rectangle" transform="translate(12.000000, 12.500000) rotate(45.000000) translate(-12.000000, -12.500000) " x="-2" y="10" width="28" height="5"></rect>
          </g>
        </g>
      </svg>
    </Button>
  );
};

export default MobileCloseButton;