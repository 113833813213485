import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import t from '../lang';
import config from '../config';
import { NavLink as Link } from 'react-router-dom';

const Table = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  padding: 5px 0;

  &:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }

  &.show + div {
    height: auto;
  }
`;

const HeaderRow = styled(Row)`
  &:hover {
    background-color: white;
    cursor: default;
  }
`;

const Col = styled.div`
  flex: 1;
`;

const ColProject = styled(Col)`
  flex: 1.6;
  cursor: pointer;
`;

const ColLocation = styled(Col)`
  display: none;
  cursor: pointer;

  @media only screen and (min-width: ${config.breakpointTableAll}) {
    display: block;  
  }
`;

const ColYear = styled(Col)`
  flex: 0.6;
  display: none;
  cursor: pointer;

  @media only screen and (min-width: ${config.breakpointTableAll}) {
    display: block;  
  }
`;

const ColStatus = styled(Col)`
  flex: 0.6;
  display: none;
  cursor: pointer;

  @media only screen and (min-width: ${config.breakpointTableStatus}) {
    display: block;  
  }
`;

const ColUsage = styled(Col)`
  flex: 0.6;
  display: none;
  cursor: pointer;

  @media only screen and (min-width: ${config.breakpointTableUsage}) {
    display: block;
  }
`;

const TableItem = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  border-bottom: 1px solid black;
  display: block;

  & > div {
    padding: 10px 0;
  }

  @media only screen and (min-width: ${config.breakpointTableAll}) {
    display: flex; 
  }
`;

const TableItemText = styled.div`
  flex: 2;
  margin-right: 100px;
`;

const TableItemImage = styled.div`
  flex: 1;
`;

const ProjectLink = styled(Link)`
  &:hover {
    color: inherit;
  }
`;

class ProjectsPage extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.orderByName = this.orderByName.bind(this);
    this.orderByYear = this.orderByYear.bind(this);
    this.orderByLocation = this.orderByLocation.bind(this);
    this.orderByStatus = this.orderByStatus.bind(this);
    this.orderByUsage = this.orderByUsage.bind(this);
    this.state = {
      projects: []
    }
  }

  handleClick(e) {
    e.stopPropagation();
    e.currentTarget.classList.toggle('show');
  }

  componentDidMount() {
    this.props.promise.then(result => {
      
      let propertyName = result.entries ? 'entries' : 'projects';

      this.setState(prevState => ({
        isLoaded: true,
        projects: result[propertyName]
      }));

      this.orderByYear();
    });
  }

  orderByYear() {
    const newData = [...this.state.projects];

    newData.sort((a, b) => {
      let sortA = a.date_to ? a['date_to'] : a['date_from'];
      let sortB = b.date_to ? b['date_to'] : b['date_from'];

      if (!a.date_from) sortA = 1;
      if (!b.date_from) sortB = 1;

      if (sortA > sortB) {
        return -1;
      } else if (sortA < sortB) {
        return 1;
      } else {
        return 0;
      }
    });

    this.setState({ projects: newData });
  }

  orderByName() {
    const newData = [...this.state.projects];

    newData.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    });

    this.setState({ projects: newData });
  }

  orderByLocation() {
    const newData = [...this.state.projects];

    newData.sort((a, b) => {
      if (a.location > b.location) {
        return 1;
      } else if (a.location < b.location) {
        return -1;
      } else {
        return 0;
      }
    });

    this.setState({ projects: newData });
  }

  orderByStatus() {
    const newData = [...this.state.projects];

    newData.sort((a, b) => {
      if (t(a.status) > t(b.status)) {
        return 1;
      } else if (t(a.status) < t(b.status)) {
        return -1;
      } else {
        return 0;
      }
    });

    this.setState({ projects: newData });
  }

  orderByUsage() {
    const newData = [...this.state.projects];

    newData.sort((a, b) => {
      if (t(a.usage) > t(b.usage)) {
        return 1;
      } else if (t(a.usage) < t(b.usage)) {
        return -1;
      } else {
        return 0;
      }
    });

    this.setState({ projects: newData });
  }

  render() {
    const data = this.state.projects || [];

    if (data.length > 0) {
      
      return (
        <Table>
          <HeaderRow>
            <ColProject onClick={this.orderByName}>{t('project')}</ColProject>
            <ColLocation onClick={this.orderByLocation}>{t('location')}</ColLocation>
            <ColYear onClick={this.orderByYear}>{t('year')}</ColYear>
            <ColStatus onClick={this.orderByStatus}>{t('status')}</ColStatus>
            <ColUsage onClick={this.orderByUsage}>{t('usage')}</ColUsage>
          </HeaderRow>
  
          {data.map((item, index) => {
            let date = item.date_to !== '' ? `${item.date_from}-${item.date_to}` : `${item.date_from}`;
  
            return (
              <Fragment key={index}>
                <Row onClick={this.handleClick}>
                  <ColProject>{t(item, 'name')}</ColProject>
                  <ColLocation>{t(item, 'location')}</ColLocation>
                  <ColYear>{date}</ColYear>
                  <ColStatus>{t(item.status)}</ColStatus>
                  <ColUsage>{t(item.usage)}</ColUsage>
                </Row>
                <TableItem>
                  <TableItemText>
                    <div dangerouslySetInnerHTML={{ __html: t(item, 'description') }}></div>
                    <div>
                      <ProjectLink to={{
                        pathname: `/project/${item.name_slug}`,
                        state: { from: `${this.props.origin}` }
                      }}>
                        { t('to_project') }
                      </ProjectLink>
                    </div>  
                  </TableItemText>
                  <TableItemImage><img src={`${config.adminBase}/${item.image.path}`} alt={t(item, 'name')} onContextMenu={(e) => {e.preventDefault(); return false;}}/></TableItemImage>
                </TableItem>
              </Fragment>
            );
          })}
  
        </Table>
      );
    }
    
    return null;
  }
}

export default ProjectsPage;