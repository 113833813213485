import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import Header from './components/Header';
import Navigation from './components/Navigation';
import NewsPage from './pages/NewsPage';
import ArchitecturePage from './pages/ArchitecturePage';
import UrbanDesignPage from './pages/UrbanDesignPage';
import ProjectsPage from './pages/ProjectsPage';
import ProjectPage from './pages/ProjectPage';
import PhilosophyPage from './pages/PhilosophyPage';
import StructurePage from './pages/StructurePage';
import ContactPage from './pages/ContactPage';
import NotFoundPage from './pages/NotFoundPage';
import ServicePage from './pages/ServicePage';
import EquipmentPage from './pages/EquipmentPage';
import TeamPage from './pages/TeamPage';
import ManagementPage from './pages/ManagementPage';
import EngagementPage from './pages/EngagementPage';
import CareerPage from './pages/CareerPage';
import PublicationsPage from './pages/PublicationsPage';
import AwardsPage from './pages/AwardsPage';
import LecturesPage from './pages/LecturesPage';
import ExhibitionsPage from './pages/ExhibitionsPage';
import ContestsPage from './pages/ContestsPage';
import PrivacyPage from './pages/PrivacyPage';
import ImprintPage from './pages/ImprintPage';
import SlideOut from 'react-slide-out';
import MobileCloseButton from './components/MobileCloseButton';
import NavigationMobile from './components/NavigationMobile';
import LanguageSwitch from './components/LanguageSwitch';
import cockpit from './cockpit';
import 'whatwg-fetch';

import 'react-slide-out/lib/index.css';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileNav: false,
      currentLanguage: 'de',
      pages: {}
    };
    this.toggleMobileNavigation = this.toggleMobileNavigation.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.refHeader = React.createRef();
    this.setNewsHeight = this.setNewsHeight.bind(this);
  }

  toggleMobileNavigation() {
    this.setState((prevState) => ({showMobileNav: !prevState.showMobileNav}));
  }

  toggleLanguage() {
    this.setState((prevState) => {
      if (prevState.currentLanguage === 'de') {
        localStorage.setItem('defautlLang', 'en');
        return {currentLanguage: 'en'}
      } else {
        localStorage.setItem('defautlLang', 'de');
        return {currentLanguage: 'de'}
      }
    });

    
  }

  setNewsHeight() {
    const newsContainer = document.querySelectorAll('.news-container');
    const newsDescription = document.querySelectorAll('.news-description');
    const maxHeight = [...newsDescription].reduce((acc, cur) => {
      return Math.max(acc, cur.offsetHeight);
    }, 0);
      
    [...newsContainer].forEach(item => item.style.height = `${maxHeight + 20}px`);
  }

  componentDidUpdate() {
    this.setNewsHeight();
  }

  componentDidMount() {
    if (localStorage.getItem('defautlLang')) {
      this.setState({currentLanguage: localStorage.getItem('defautlLang')});
    } else {
      const userLang = navigator.language || navigator.userLanguage; 
    
      if (userLang !== 'de-DE') {
        this.setState({currentLanguage: 'en'});
      }
    }

    //cockpit.getCollection('news').then(result => this.setState(prevState => Object.assign(prevState.pages, {news: result.entries})));
    cockpit.getNews().then(result => {
      this.setState(prevState => Object.assign(prevState.pages, {news: result.entries}));
      this.setNewsHeight();

      window.addEventListener('resize', this.setNewsHeight);
    });
    cockpit.getCollection('projects').then(result => this.setState(prevState => Object.assign(prevState.pages, {projects: result.entries})));
    cockpit.getOfficeList('publications').then(result => this.setState(prevState => Object.assign(prevState.pages, {publications: result.entries})));
    cockpit.getOfficeList('awards').then(result => this.setState(prevState => Object.assign(prevState.pages, {awards: result.entries})));
    cockpit.getOfficeList('lectures').then(result => this.setState(prevState => Object.assign(prevState.pages, {lectures: result.entries})));
    cockpit.getOfficeList('exhibitions').then(result => this.setState(prevState => Object.assign(prevState.pages, {exhibitions: result.entries})));
    cockpit.getOfficeList('contests').then(result => this.setState(prevState => Object.assign(prevState.pages, {contests: result.entries})));
    
    cockpit.getSingleton('architecture').then(result => this.setState(prevState => Object.assign(prevState.pages, {architecture: result.projects})));
    cockpit.getSingleton('urban_design').then(result => this.setState(prevState => Object.assign(prevState.pages, {urban_design: result.projects})));

    cockpit.getSingleton('philosophy').then(result => this.setState(prevState => Object.assign(prevState.pages, {philosophy: result})));
    cockpit.getSingleton('structure').then(result => this.setState(prevState => Object.assign(prevState.pages, {structure: result})));
    cockpit.getSingleton('service').then(result => this.setState(prevState => Object.assign(prevState.pages, {service: result})));
    cockpit.getSingleton('equipment').then(result => this.setState(prevState => Object.assign(prevState.pages, {equipment: result})));
    cockpit.getSingleton('team').then(result => this.setState(prevState => Object.assign(prevState.pages, {team: result})));
    cockpit.getSingleton('management').then(result => this.setState(prevState => Object.assign(prevState.pages, {management: result})));
    cockpit.getSingleton('engagement').then(result => this.setState(prevState => Object.assign(prevState.pages, {engagement: result})));
    cockpit.getSingleton('career').then(result => this.setState(prevState => Object.assign(prevState.pages, {career: result})));
    cockpit.getSingleton('contact').then(result => this.setState(prevState => Object.assign(prevState.pages, {contact: result})));
    cockpit.getSingleton('privacy_policy').then(result => this.setState(prevState => Object.assign(prevState.pages, {privacy_policy: result})));
  }

  render() {
    window.currentLanguage = this.state.currentLanguage;
    
    return (
      <div className="App">
        <Header ref={this.refHeader}>
          <Navigation match={this.props.match} data={this.state.pages} toggleHandler={this.toggleMobileNavigation} currentPage={this.props.location.pathname.split('/')[1]} lang={this.state.currentLanguage}/>
          <LanguageSwitch toggleHandler={this.toggleLanguage} currentLanguage={this.state.currentLanguage}/>
        </Header>
        <main className="App-main">
          <Switch>
            <Route exact path="/" render={props => <NewsPage {...props} data={this.state.pages.news}/>}/>
            <Route path="/news" render={props => <NewsPage {...props} data={this.state.pages.news}/>}/>
            
            <Route exact path="/architecture/selection" render={props => <ArchitecturePage {...props} data={this.state.pages.architecture}/>}/>
            <Route exact path="/urban-design/selection" render={props => <UrbanDesignPage {...props} data={this.state.pages.urban_design}/>}/>
            {/*<Route exact path="/architecture/all" render={props => <ProjectsPage {...props} data={this.state.pages.projects} origin="/architecture/all"/>}/>*/}
            
            <Route exact 
              path="/architecture/all" 
              render={
                props => <ProjectsPage {...props} 
                promise={cockpit.getCollection('projects')}
                data={this.state.pages.projects} 
                origin="/architecture/all"/>
              }
            />
            
            {/*<Route exact path="/urban-design/all" render={props => <ProjectsPage {...props} data={this.state.pages.projects} origin="/urban-design/all"/>}/>*/}
            
            <Route exact 
              path="/urban-design/all" 
              render={props => <ProjectsPage {...props} 
                promise={cockpit.getCollection('projects')}
                data={this.state.pages.projects} 
                origin="/urban-design/all"/>
              }
            />
            
            <Route exact path="/project/all" render={props => <ProjectsPage {...props} promise={cockpit.getCollection('projects')} data={this.state.pages.projects}/>}/>
            
            <Route exact 
              path="/project/:slug/:image?" 
              render={
                props => <ProjectPage {...props} 
                  origin="project"
                  promise={cockpit.getCollection('projects')}
                  data={this.state.pages.projects} 
                  headerRef={this.refHeader}/>
              }
            />
            
            <Route exact 
              path="/architecture/:slug/:image?" 
              render={
                props => <ProjectPage {...props} 
                  origin="architecture"
                  promise={cockpit.getSingleton('architecture')} 
                  data={this.state.pages.architecture} 
                  headerRef={this.refHeader}/>
              }
            />

            <Route exact 
              path="/urban-design/:slug/:image?" 
              render={
                props => <ProjectPage {...props} 
                  origin="urban-design"
                  promise={cockpit.getSingleton('urban_design')} 
                  data={this.state.pages.urban_design} 
                  headerRef={this.refHeader}/>
              }
            />

            <Route path="/office/profile/philosophy" render={props => <PhilosophyPage {...props} data={this.state.pages.philosophy}/>}/>
            <Route path="/office/profile/service" render={props => <ServicePage {...props} data={this.state.pages.service}/>}/>
            <Route path="/office/profile/structure" render={props => <StructurePage {...props} data={this.state.pages.structure}/>}/>
            <Route path="/office/profile/equipment" render={props => <EquipmentPage {...props} data={this.state.pages.equipment}/>}/>
            <Route path="/office/people/team" render={props => <TeamPage {...props} data={this.state.pages.team}/>}/>
            <Route path="/office/people/management" render={props => <ManagementPage {...props} data={this.state.pages.management}/>}/>
            <Route path="/office/people/engagement" render={props => <EngagementPage {...props} data={this.state.pages.engagement}/>}/>
            <Route path="/office/people/career" render={props => <CareerPage {...props} data={this.state.pages.career}/>}/>
            <Route path="/office/references/publications" render={props => <PublicationsPage {...props} data={this.state.pages.publications}/>}/>
            <Route path="/office/references/awards" render={props => <AwardsPage {...props} data={this.state.pages.awards}/>}/>
            <Route path="/office/references/lectures" render={props => <LecturesPage {...props} data={this.state.pages.lectures}/>}/>
            <Route path="/office/references/exhibitions" render={props => <ExhibitionsPage {...props} data={this.state.pages.exhibitions}/>}/>
            <Route path="/office/references/contests" render={props => <ContestsPage {...props} data={this.state.pages.contests}/>}/>
            <Route path="/contact" render={props => <ContactPage {...props} data={this.state.pages.contact}/>}/>
            <Route path="/privacy-policy" render={props => <PrivacyPage {...props} data={this.state.pages.privacy_policy}/>}/>
            <Route path="/imprint" render={props => <ImprintPage {...props} data={this.state.pages.privacy_policy}/>}/>

            <Route path="/architecture" render={() => <Redirect to="/architecture/selection"/>}/>
            <Route path="/urban-design" render={() => <Redirect to="/urban-design/selection"/>}/>
            <Route path="/office/references" render={() => <Redirect to="/office/references/publications"/>}/>
            <Route path="/office/people" render={() => <Redirect to="/office/people/team"/>}/>
            <Route path="/office/profile" render={() => <Redirect to="/office/profile/philosophy"/>}/>
            <Route path="/office" render={() => <Redirect to="/office/profile/philosophy"/>}/>
            <Route component={NotFoundPage}/>
          </Switch>
        </main>
        <SlideOut
          title={<MobileCloseButton toggleHandler={this.toggleMobileNavigation}/>} 
          isOpen={this.state.showMobileNav}
          onOutsideClick={() => {this.toggleMobileNavigation()}}>

          <NavigationMobile toggleHandler={this.toggleMobileNavigation} languageHandler={this.toggleLanguage} currentLanguage={this.state.currentLanguage}/>
        </SlideOut>
      </div>
    );
  }
}

export default withRouter(App);
