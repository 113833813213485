import config from './config';

const getCollection = (collection, body = { filter: {published: true}}) => {
  return fetch(`${config.adminBase}/api/collections/get/${collection}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
  .then(res => res.json());
};

const getSingleton = (singleton) => {
  return fetch(`${config.adminBase}/api/singletons/get/${singleton}?populate=1`).then(res => res.json());
};

const getNews = () => {
  return fetch(`${config.adminBase}/api/collections/get/news?populate=1`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      filter: { published: true },
      sort: { date: -1 }
    })
  })
  .then(res => res.json());
};

const getOfficeList = (collection) => {
  return fetch(`${config.adminBase}/api/collections/get/${collection}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      filter: { published: true },
      sort: { year: -1 }
    })
  })
  .then(res => res.json());
};

export default {
  getCollection,
  getSingleton,
  getNews,
  getOfficeList
};