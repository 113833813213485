import React, { Component } from 'react';
import styled from 'styled-components';
import config from '../config';
import Slider from 'react-slick';
import ImageLoader from './ImageLoader';
import arrowLeft from '../assets/images/arrow-simple-left.png';
import arrowRight from '../assets/images/arrow-simple-right.png';
import arrowUp from '../assets/images/arrow-simple-up.png';
import arrowDown from '../assets/images/arrow-simple-down.png';
import playButton from '../assets/images/play_button.svg';

const ProjectGallery = styled.div`
  position: relative;
  .slick-slide {
    height: ${props => props.galleryHeight}px !important;

    & > div {
      height: ${props => props.galleryHeight}px !important;
    }
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 33.33%;
  z-index: 10;
`;

const PlayButton = styled.button`
  position: absolute;
  z-index: -10;
  cursor: pointer;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  width: 100px;
  height: 100px;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  opacity: 0;
  transition: all 0.2s ease-in;

  &:focus, &:active {
    outline: none;
  }
  
  &.show {
    z-index: 10000;
    opacity: 1;
  }
`;

const ArrowLeft = styled(Arrow)`
  top: 0;
  left: 0;
  height: 100%;
  cursor: url(${arrowLeft}), auto;
`;

const ArrowRight = styled(Arrow)`
  top: 0;
  right: 0;
  height: 100%;
  cursor: url(${arrowRight}), auto;
`;

const ArrowUp = styled(Arrow)`
  top: 0;
  left: 33.33%;
  height: 50%;
  cursor: url(${arrowUp}), auto;
`;

const ArrowDown = styled(Arrow)`
  top: 50%;
  left: 33.33%;
  height: 50%;
  cursor: url(${arrowDown}), auto;
`;

//const SliderImage = styled.img`
const SliderImage = styled(ImageLoader)`
  height: 100%;
  object-fit: contain;
  opacity: 0;

  &.sliderimage-loaded {
    opacity: 1;
  }
`;

const SliderVideo = styled.div`
  height: 100%;
  object-fit: contain;
  opacity: 1;
  cursor: pointer;
  z-index: 10000;
  position: relative;

  video {
    width: 100%;
    height: 100%;
  }
`;

const Loading = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);

  &.sliderimage-loaded {
    opacity: 0;
  }
`;

/**
 * COMPONENT
 */
class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: parseInt(this.props.match.params.image) || 0,
      project: this.props.currentProject,
      projectIndex: 0,
      projectSlug: this.props.currentProject.name_slug,
      isAnimating: false,
      from: null,
      firstImageLoaded: false,
      playButton: ''
    };

    this.handlePrevImage = this.handlePrevImage.bind(this);
    this.handleNextImage = this.handleNextImage.bind(this);
    this.handlePrevProject = this.handlePrevProject.bind(this);
    this.handleNextProject = this.handleNextProject.bind(this);
    this.setGalleryHeight = this.setGalleryHeight.bind(this);
    this.onBackButtonEvent = this.onBackButtonEvent.bind(this);
    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
    this.startVideo = this.startVideo.bind(this);
    this.checkPlayButton = this.checkPlayButton.bind(this);
    this.domHeader = null;
    this.domFooter = null;
    this.video = null;
    //this.video = React.createRef();
    this.playButton = React.createRef();
    this.slider = React.createRef();
  }

  componentDidMount() {
    this.setState(prevState => ({ 
      projectIndex: this.getProjectIndex(),
      domHeader: document.getElementById('header'),
      domFooter: document.getElementById('footer')
    }));

    if (this.props.location.state) {
      this.setState({ from: this.props.location.state.from });
    }

    this.domHeader = document.getElementById('header');
    this.domFooter = document.getElementById('footer');
    this.video = document.getElementById('video');

    this.setGalleryHeight();
    window.onpopstate = this.onBackButtonEvent;
    window.addEventListener('resize', this.setGalleryHeight);

    this.props.captionHandler(this.state.project.gallery[0].meta.title);

    if (this.state.project.video_position) {
      this.props.currentProject.gallery.splice(this.state.project.video_position - 1, 0, this.props.currentProject.gallery[0]);
    }
    
    this.checkPlayButton(this.state.project.video_position && this.state.imageIndex === parseInt(this.state.project.video_position) - 1);
  }

  componentWillUnmount = () => {
    if (this.state.project.video_position) {
      this.stopVideo();
    }
  };

  checkPlayButton(isVideo) {
    if (isVideo) {
      // this.playButton.style.display = 'block';
      this.setState({playButton: 'show'});
    } else {
      // this.playButton.style.display = 'none';
      this.setState({playButton: ''});
      if (this.video) {
        this.stopVideo();
      }
    }
  }
  
  startVideo() {
    this.video = document.getElementById('video');
    this.video.play();
    this.setState({playButton:''});
  }

  stopVideo() {
    this.video.pause();
    this.video.currentTime = 0;
  }

  onBackButtonEvent() {
    if (this.state.from) {
      this.props.history.push(`${this.state.from}`);
    }
  }

  setGalleryHeight() {
    this.setState(prevState => ({
      galleryHeight: window.innerHeight - (prevState.domHeader.offsetHeight + prevState.domFooter.offsetHeight) - 25
    }));
  }
  
  handlePrevImage() {
    if (!this.state.isAnimating) {
      this.slider.slickPrev();

      let newImageIndex;

      if (this.state.imageIndex - 1 < 0) {
        newImageIndex = this.state.project.gallery.length - 1;
      } else {
        newImageIndex = this.state.imageIndex - 1;
      }

      this.checkPlayButton(this.state.project.video_position && newImageIndex === parseInt(this.state.project.video_position) - 1);
      
      this.setState(prevState => ({ imageIndex: newImageIndex }));
      this.props.history.push(`/${this.props.origin}/${this.state.projectSlug}/${newImageIndex}`);

      this.props.captionHandler(this.state.project.gallery[newImageIndex].meta.title);
    }
  }

  handleNextImage() {
    if (!this.state.isAnimating) {
      this.slider.slickNext();

      let newImageIndex;

      if (this.state.imageIndex + 1 > this.state.project.gallery.length - 1) {
        newImageIndex = 0;
      } else {
        newImageIndex = this.state.imageIndex + 1;
      }

      this.checkPlayButton(this.state.project.video_position && newImageIndex === parseInt(this.state.project.video_position) - 1);
      
      this.setState(prevState => ({ imageIndex: newImageIndex }));
      this.props.history.push(`/${this.props.origin}/${this.state.projectSlug}/${newImageIndex}`);

      this.props.captionHandler(this.state.project.gallery[newImageIndex].meta.title);
    }
  }

  handlePrevProject() {
    if (!this.state.isAnimating) {
      let newProjectIndex;

      if (this.state.projectIndex - 1 < 0) {
        newProjectIndex = this.props.projects.length - 1;
      } else {
        newProjectIndex = this.state.projectIndex - 1;
      }

      let newProjectSlug = this.props.projects[newProjectIndex].name_slug;

      this.setState(prevState => ({ 
        projectIndex: newProjectIndex,
        projectSlug: newProjectSlug,
        project: this.props.projects[newProjectIndex],
        imageIndex: 0,
        firstImageLoaded: false,
        isAnimating: false
      }));

      this.checkPlayButton(this.props.projects[newProjectIndex].video_position && parseInt(this.props.projects[newProjectIndex].video_position) - 1 === 0);

      this.props.history.push(`/${this.props.origin}/${newProjectSlug}`);
      this.slider.slickGoTo(0, true);

      this.props.captionHandler(this.state.project.gallery[0].meta.title);
    }
  }
  
  handleNextProject() {
    if (!this.state.isAnimating) {
      let newProjectIndex;

      if (this.state.projectIndex + 1> this.props.projects.length - 1) {
        newProjectIndex = 0;
      } else {
        newProjectIndex = this.state.projectIndex + 1;
      }

      let newProjectSlug = this.props.projects[newProjectIndex].name_slug;
      
      this.setState(prevState => ({ 
        projectIndex: newProjectIndex,
        projectSlug: newProjectSlug,
        project: this.props.projects[newProjectIndex],
        imageIndex: 0,
        firstImageLoaded: false,
        isAnimating: false
      }));

      this.checkPlayButton(this.props.projects[newProjectIndex].video_position && parseInt(this.props.projects[newProjectIndex].video_position) - 1 === 0);

      this.props.history.push(`/${this.props.origin}/${newProjectSlug}`);
      this.slider.slickGoTo(0, true);

      this.props.captionHandler(this.state.project.gallery[0].meta.title);
    }
  }

  getProjectIndex() {
    return this.props.projects.findIndex(project => project.name_slug === this.state.projectSlug);
  }

  handleImageLoad(e) {
    const actuelImagePath = e.target.src.split('/');
    const actualImageSrc = actuelImagePath[actuelImagePath.length - 1];
    const firstProjectImage = config.adminBase + this.state.project.gallery[0].path;
    const firstProjectPath = firstProjectImage.split('/');
    const firstProjectSrc = firstProjectPath[firstProjectPath.length - 1];
 
    if (firstProjectSrc === actualImageSrc) {
      this.setState({ firstImageLoaded: true });
    }
  }

  render() {
    const sliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      initialSlide: this.state.imageIndex,
      beforeChange: () => { 
        this.setState(prevState => ({ isAnimating: true }));
        setTimeout(() => {
          this.setState(prevState => ({ isAnimating: false }));
        }, 500);
      },
      afterChange: () => { this.setState(prevState => ({ isAnimating: false })) }
    };
    
    if (this.props.currentProject.gallery.length > 0) {
      return (
        <ProjectGallery galleryHeight={this.state.galleryHeight} onContextMenu={(e) => {e.preventDefault(); return false;}}>
          <ArrowLeft onClick={this.handlePrevImage}></ArrowLeft>
          <ArrowRight onClick={this.handleNextImage}></ArrowRight>
          <ArrowUp onClick={this.handlePrevProject}></ArrowUp>
          <ArrowDown onClick={this.handleNextProject}></ArrowDown>
          
          <PlayButton ref={el => this.playButton = el } onClick={this.startVideo} className={this.state.playButton}><img src={playButton} alt="play"/></PlayButton>
          
          <Slider ref={el => (this.slider = el)} {...sliderSettings}>
          {this.state.project.gallery.map((image, index) => {
            if (this.state.project.video_position && index === parseInt(this.state.project.video_position) - 1) {
              return (
           
                  <SliderVideo key={`video-${index}`}>
                    <video id="video" src={`${config.adminBase}/${this.state.project.video}`} muted loop={true} controls={false}></video>
                  </SliderVideo>
            
              );
            } else {

              
              return (
                // <SliderImage key={index} src={`${config.adminBase}/${image.path}`} alt={this.props.currentProject.name} />
                <SliderImage 
                key={index} 
                imageUrl={`${config.adminBase}/${image.path}`} 
                alt={this.props.currentProject.name} 
                onLoad={this.handleImageLoad}
                className={this.state.firstImageLoaded ? 'sliderimage-loaded' : ''}
                title={image.meta.title}
                />
              );
            }
          })}

          </Slider>
          <Loading className={this.state.firstImageLoaded ? 'sliderimage-loaded' : ''}>Loading...</Loading>
        </ProjectGallery>
      );
    } else {
      return null;
    }
  }

}

export default Gallery;