import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Gallery from '../components/Gallery';
import t from '../lang';
import config from '../config';
import Modal from 'react-responsive-modal';
import {Helmet} from "react-helmet";

const ProjectWraper = styled.div`
  position: relative;
  overflow: hidden;
  display: none;

  @media only screen and (min-width: ${config.breakpointProject}) {
    display: block;
  }

  &.show {
    #projectTitle {
      visibility: hidden;
    }

    #projectDescription {
      bottom: 50px;
    }

    #imageCaption {
      visibility: hidden;
    }
  }
`;

const MobileWrapper = styled.div`
  display: block;
  font-family: Courier, serif;

  @media only screen and (min-width: ${config.breakpointProject}) {
    display: none;
  }

  h2 {
    font-size: 1.4rem;
    font-family: 'HelveticaNeueW01-65Medi', Helvetica, Arial, sans-serif;
  }

  h3 {
    font-size: inherit;
    margin-bottom: 2rem;
    font-family: Courier, serif;
    font-weight: normal;
    font-size: 1.2rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
  }
`;

const ProjectFooter = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: flex-start;

  /* h2 {
    display: inline-block;
    flex: 3;
    padding-right: 80px;
  } */

  Caption {
    display: block;
  }

  button {
    flex: 2;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    text-align: left;

    &:focus {
      outline: none;
    }
  }
`;

const ProjectDescription = styled.div`
  position: absolute;
  bottom: -100%;
  transition: all 0.3s linear;
  background-color: white;
  padding: 1.2rem 0;
  display: flex;
  width: 100%;
  z-index: 100;
`;

const ProjectDescriptionText = styled.div`
  flex: 3;
  padding-right: 80px;

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: inherit;
    margin-bottom: 2rem;
    font-family: Courier, serif;
    font-weight: normal;
    font-size: 1.2rem;
  }

  div {
    font-family: Courier, serif;
    font-size: 1.2rem;
  }
`;

const ProjectDescriptionData = styled.div`
  flex: 2;
  font-family: Courier, serif;

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
  }
`;

const MobileGallery = styled.div`
  margin-bottom: 1rem;

  div {
    margin-bottom: 1rem;
  }
`;

const ModalButton = styled.button`
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  font-family: Courier, serif;
  font-size: 1.2rem;
  text-decoration: underline;

  &:focus {
    outline: none;
  }
`;

const ModalDescription = styled.div`
  padding: 50px 30px 30px 30px;
  font-family: Courier, serif;
  font-size: 1.2rem;
`;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;

const Caption = styled.p`
  display: block;
`;

const CaptionWrapper = styled.div`
  display: inline-block;
  flex: 3;
  padding-right: 80px;  
`;

class ProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      projects: null,
      modalOpen: false,
      caption: null
    };
    this.footerRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.toggleDescription = this.toggleDescription.bind(this);
  }

  componentDidMount() {
    this.props.promise.then(result => {
      
      let propertyName = result.entries ? 'entries' : 'projects';

      

      this.setState(prevState => ({
        isLoaded: true,
        projects: result[propertyName]
      }));
    });
  }

  componentWillUnmount() {
    
  }

  toggleDescription(e) {
    this.wrapperRef.current.classList.toggle('show');
  }

  onOpenModal = () => {

    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  onCaption = caption => {
    this.setState({ caption: caption });
  };

  render() {
    const { isLoaded, projects, modalOpen } = this.state;
    
    if (isLoaded) {
      const currentProject = projects.filter(item => item.name_slug === this.props.match.params.slug).pop();

      if (currentProject) {
        const location = currentProject.location ? <li>{ t('location') }: {t(currentProject, 'location')}</li> : null;
        const year = currentProject.year ? <li>{ t('year') }: {t(currentProject, 'year')}</li> : null;
        
        let date = null;

        if (currentProject.date_to !== '') {
          date = `${t('year')}: ${currentProject.date_from}-${currentProject.date_to}`;
        } else if (currentProject.date_from) {
          date = `${t('year')}: ${currentProject.date_from}`;
        }

        const contest = currentProject.contest ? <li>{ t('contest') }: {t(currentProject, 'contest')}</li> : null;
        const client = currentProject.client ? <li>{ t('client') }: {t(currentProject, 'client')}</li> : null;
        const size = currentProject.size ? <li>{ t('size') }: {t(currentProject, 'size')}</li> : null;
        const architects = currentProject.architects ? <li>{ t('architects') }: {t(currentProject, 'architects')}</li> : null;
        const team = currentProject.team ? <li>{ t('planning_team') }: {t(currentProject, 'team')}</li> : null;
        const staff = currentProject.staff ? <li>{ t('staff') }: {t(currentProject, 'staff')}</li> : null;
        const moreDetails = currentProject.description_long ? <ModalButton onClick={this.onOpenModal}>{ t('more_details') }</ModalButton> : null;

        return (
          <Fragment>
            <Helmet>
                <title>{currentProject.name}</title>
                <meta name="description" content={currentProject.headline} />
            </Helmet>

            <ProjectWraper ref={this.wrapperRef}>
              <Gallery {...this.props} projects={projects} currentProject={currentProject} captionHandler={this.onCaption}></Gallery>
      
              <ProjectFooter id="footer">
                <CaptionWrapper>
                  <Caption id="projectCaption">{this.state.caption}</Caption>
                  <h2 id="projectTitle">{t(currentProject, 'name')}</h2>
                </CaptionWrapper>
                <button onClick={this.toggleDescription}>{t('project_description')}</button>
              </ProjectFooter>
      
              <ProjectDescription id="projectDescription">
                <ProjectDescriptionText>
                    <h2>{t(currentProject, 'name')}</h2>
                    <h3>{t(currentProject, 'headline')}</h3>
                    <div dangerouslySetInnerHTML={{ __html: t(currentProject, 'description') }}></div>
                    <p>{moreDetails}</p>
                </ProjectDescriptionText>
                <ProjectDescriptionData>
                    <h2>&nbsp;</h2>
                    <h3>&nbsp;</h3>
                    <ul>
                      {location}
                      {date}
                      {contest}
                      {client}
                      {size}
                      {architects}
                      {team}
                      {staff}
                    </ul>
                </ProjectDescriptionData>
              </ProjectDescription>
            </ProjectWraper>

            <MobileWrapper>
              <MobileGallery>
                {currentProject.gallery.map((image, index) => {
                  return (
                    <React.Fragment>
                      <div>
                        <img key={index} src={`${config.adminBase}/${image.path}`} alt={t(currentProject, 'name')}/>
                        <Caption>{image.meta.title}</Caption>
                      </div>
                    </React.Fragment>
                  );
                })}
              </MobileGallery>
              <h2>{t(currentProject, 'name')}</h2>
              <h3>{t(currentProject, 'headline')}</h3>
              <div dangerouslySetInnerHTML={{ __html: t(currentProject, 'description') }}></div>
              <ul>
                {location}
                {year}
                {contest}
                {client}
                {size}
                {architects}
                {team}
                {staff}
              </ul>
            </MobileWrapper>

            <Modal 
              styles={{
                modal: {
                  maxWidth: '800px',
                  width: '90%'
                },
                closeButton: {
                  cursor: 'pointer'
                }
              }}
              open={modalOpen} 
              onClose={this.onCloseModal} 
              center
            >
              <ModalDescription dangerouslySetInnerHTML={{ __html: t(currentProject, 'description_long') }}></ModalDescription>
            </Modal>
          </Fragment>
          
        );
      } else {
        return <NotFound>{t('project_not_found')}</NotFound>
      }
    }
    
    return <NotFound>Loading..</NotFound>;
  }
}

export default ProjectPage;