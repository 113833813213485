import React from 'react';
import styled from 'styled-components';
import t from '../lang';
import config from '../config';
import { NavLink as Link } from 'react-router-dom';

// STYLING
const News = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(20rem, 1fr));
`;

const NewsItem = styled.div`
  margin-bottom: 15px;
`;

const NewsImage = styled.img`
  width: 100%;
  margin-bottom: 5px;
`;

const NewsText = styled.div`
  a:hover {
    color: inherit;
  }
`;

const NewsPage = (props) => {
  //const data = props.data || [];

  if (props.data) {
    return (
      <News>
        {props.data.map((item, index) => {
          const link = item.project_link ? <Link to={{pathname: `/project/${item.project_link.name_slug}`, state: { from: '/news' }}}>{t('to_project')}</Link> : null;

          return (
            <NewsItem className="news-items" key={index}>
              <NewsImage src={`${config.adminBase}/${item.image.path}`} alt={t(item, 'headline')} />
              <div className="news-container">
                <h2>{t(item, 'headline')}</h2>
                <NewsText dangerouslySetInnerHTML={{ __html: t(item, 'text') }} className="news-description"></NewsText>
              </div>
              {link}
            </NewsItem>
          );
        })}
      </News>
    );
  } else {
    return null;
  }
}

export default NewsPage;