import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  padding: 2.0rem 0 4.0rem 0;

  & > div {
    flex: 1;
  }
`;

const HeaderLogo = styled.h1`
  flex: 0 0 300px;
`;

const HeaderLogoLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const Header = React.forwardRef((props, ref) => {
  return (
    <HeaderContainer ref={ref} id="header">
      <HeaderLogo><HeaderLogoLink to="/">Beer Bembé Dellinger</HeaderLogoLink></HeaderLogo>
      {props.children}
    </HeaderContainer>
  );
});

export default Header;