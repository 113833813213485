import React from 'react';
import t from '../lang';
import styled from 'styled-components';
import config from '../config';

const Content = styled.div`
  padding-bottom: 50px;

  @media only screen and (min-width: ${config.breakpointProject}) {
    width: 60%;
  }
`;

const PrivacyPage = (props) => {
  const data = props.data || {};

  if (Object.keys(data).length > 0) {
    return (
      <Content dangerouslySetInnerHTML={{ __html: t(data, 'text') }}></Content>
    );
  }

  return null;
};

export default PrivacyPage;