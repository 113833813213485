import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import config from '../config';
import t from '../lang';

const NavContainer = styled.div`
  display: flex;
`;

const Nav = styled.nav`
  display: none;

  &:nth-child(2) {
    margin-left: auto;
  }

  @media only screen and (min-width: ${config.breakpointNavigation}) {
    display: block;
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  display: inline-block;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const NavLink = styled(Link)`
  border-bottom: 1px solid transparent;
  color: inherit;
  text-decoration: none;
  padding-bottom: 3px;

  &:hover, &.active {
    border-bottom: 1px solid black;
    color: inherit;
    text-decoration: none;
  }
`;

const ToggleButton = styled.button`
  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
  padding: 0;

  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${config.breakpointNavigation}) {
    display: none;
  }
`;

const Navigation = (props) => {
  let subNavigation = null;

  switch (props.currentPage) {
    case 'architecture': 
      subNavigation = (
        <NavList>
          <NavItem><NavLink to="/architecture/selection" activeClassName="active">{t('selection')}</NavLink></NavItem>
          <NavItem><NavLink to="/architecture/all" activeClassName="active">{t('all')}</NavLink></NavItem>
        </NavList>
      );
      break;
    case 'urban-design':
      subNavigation = (
        <NavList>
          <NavItem><NavLink to="/urban-design/selection" activeClassName="active">{t('selection')}</NavLink></NavItem>
          <NavItem><NavLink to="/urban-design/all" activeClassName="active">{t('all')}</NavLink></NavItem>
        </NavList>
      );
      break;
    case 'office':
      subNavigation = (
        <NavList>
          <NavItem><NavLink to="/office/profile" activeClassName="active">{t('profile')}</NavLink></NavItem>
          <NavItem><NavLink to="/office/people" activeClassName="active">{t('people')}</NavLink></NavItem>
          <NavItem><NavLink to="/office/references" activeClassName="active">{t('references')}</NavLink></NavItem>
        </NavList>
      );
      break;
    default:
      break;
  }

  return (
    <NavContainer>
      <Nav>
        {subNavigation}
      </Nav>
      <Nav>
        <NavList>
          <NavItem><NavLink to={{pathname: '/news', state: { data: props.data.news }}} activeClassName="active">{t('news')}</NavLink></NavItem>
          <NavItem><NavLink to="/architecture" activeClassName="active">{t('architecture')}</NavLink></NavItem>
          <NavItem><NavLink to="/urban-design" activeClassName="active">{t('urban-design')}</NavLink></NavItem>
          <NavItem><NavLink to="/office" activeClassName="active">{t('office')}</NavLink></NavItem>
          <NavItem><NavLink to="/contact" activeClassName="active">{t('contact')}</NavLink></NavItem>
          {/* <NavItem><NavLink to="/privacy-policy" activeClassName="active">{t('privacy-policy')}</NavLink></NavItem>
          <NavItem><NavLink to="/imprint" activeClassName="active">{t('imprint')}</NavLink></NavItem> */}
        </NavList>
      </Nav>

      <ToggleButton onClick={props.toggleHandler}>
        <svg width="28px" height="23px" viewBox="0 0 28 23" xmlns="http://www.w3.org/2000/svg">
          <g id="mobile-menu-icon" fill="#000000">
            <rect id="Rectangle" x="0" y="0" width="28" height="5"></rect>
            <rect id="Rectangle" x="0" y="18" width="28" height="5"></rect>
            <rect id="Rectangle" x="0" y="9" width="28" height="5"></rect>
          </g>
        </svg>
      </ToggleButton>
    </NavContainer>
  );
};

export default Navigation;