import React from 'react';

class ImageLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageStatus: 'loading'
    };
  }

  handleImageLoad() {
    this.setState({ imageStatus: 'loaded' });
    console.log('loaded');
  }
  
  handleImageError() {
    this.setState({ imageStatus: 'failed to load' });
  }

  render() {
    return (
      <React.Fragment>
        <img 
          src={this.props.imageUrl}
          onLoad={this.props.onLoad}
          onError={this.handleImageError.bind(this)}
          alt={this.props.alt}
          className={this.props.className}
          onContextMenu={(e) => {console.log('####'); e.preventDefault(); return false;}}
          />
      </React.Fragment>
    );
  }
}

export default ImageLoader;
