import React from 'react';
import t from '../lang';
import styled from 'styled-components';
import config from '../config';
import { Link } from 'react-router-dom';

const Content = styled.div`
  padding-bottom: 50px;

  @media only screen and (min-width: ${config.breakpointProject}) {
    width: 60%;
  }
`;

const ContactPage = (props) => {
  const data = props.data || {};

  if (Object.keys(data).length > 0) {
    return (
      <React.Fragment>
        <Content dangerouslySetInnerHTML={{ __html: t(data, 'text') }}></Content>
        <Link to="/privacy-policy">Datenschutz / Impressum</Link>
      </React.Fragment>
    );
  }

  return null;
};

export default ContactPage;