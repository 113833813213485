import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import config from '../config.js';
import t from '../lang';

const SidebarContainer = styled.div`
  display: block;

  @media only screen and (min-width: ${config.breakpointSidebar}) {
    display: flex;
  }
`;

const SidebarNav = styled.nav`
  flex: 1;
  margin-bottom: 20px;

  @media only screen and (min-width: ${config.breakpointSidebar}) {
    flex: 0 0 300px;
    margin-bottom: 0;
  }
`;

const SidebarNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const SidebarNavItem = styled.li`
  margin-bottom: 0.5em;
`;

const SidebarNavLink = styled(Link)`
  border-bottom: 1px solid transparent;
  color: inherit;
  text-decoration: none;
  padding-bottom: 3px;
  display: block;
  border-bottom: 1px solid #dddddd;

  &:hover, &.active {
    border-bottom: 1px solid black;
    color: inherit;
    text-decoration: none;
  }

  @media only screen and (min-width: ${config.breakpointSidebar}) {
    display: inline-block;
    border-bottom: 1px solid transparent;
  }
`;

const MainContent = styled.div`
  flex: 1;
`;

const Sidebar = (props) => {
  let sideNavigation = null;

  switch (props.page) {
    case 'profile':
      sideNavigation = (
        <SidebarNavList>
          <SidebarNavItem><SidebarNavLink to="/office/profile/philosophy">{t('philosophy')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/profile/structure">{t('structure')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/profile/service">{t('service')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/profile/equipment">{t('equipment')}</SidebarNavLink></SidebarNavItem>
        </SidebarNavList>
      );
      break;
    case 'people':
      sideNavigation = (
        <SidebarNavList>
          <SidebarNavItem><SidebarNavLink to="/office/people/team">{t('team')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/people/management">{t('management')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/people/engagement">{t('engagement')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/people/career">{t('career')}</SidebarNavLink></SidebarNavItem>
        </SidebarNavList>
      );
      break;
    case 'references':
      sideNavigation = (
        <SidebarNavList>
          <SidebarNavItem><SidebarNavLink to="/office/references/publications">{t('publications')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/references/awards">{t('awards')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/references/lectures">{t('lectures')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/references/exhibitions">{t('exhibitions')}</SidebarNavLink></SidebarNavItem>
          <SidebarNavItem><SidebarNavLink to="/office/references/contests">{t('contests')}</SidebarNavLink></SidebarNavItem>
        </SidebarNavList>
      );  
      break;
    default:
      break;
  }

  return (
    <SidebarContainer>
      <SidebarNav>
        {sideNavigation}
      </SidebarNav>
      <MainContent>
        {props.children}
      </MainContent>
    </SidebarContainer>
  );
};

export default Sidebar;