import React from 'react';
import styled from 'styled-components';
import config from '../config';

const Button = styled.button`
  display: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 0 0 30px;
  text-transform: uppercase;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${config.breakpointNavigation}) {
    display: block;
  }
`;

const LanguageSwitch = (props) => {
  return (
    <Button onClick={props.toggleHandler}>{props.currentLanguage === 'de' ? 'EN' : 'DE'}</Button>
  );
};

export default LanguageSwitch;