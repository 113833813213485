import React from 'react';
import styled from 'styled-components';
import t from '../lang';

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;

const NotFoundPage = () => {
  return (
    <NotFound>{t('page_not_found')}</NotFound>
  );
};

export default NotFoundPage;