export default {
  breakpointNavigation: '1010px',
  breakpointTableStatus: '970px',  
  breakpointTableUsage: '850px',  
  breakpointTableAll: '750px',  
  breakpointSidebar: '700px',
  breakpointOffice: '1020px',
  breakpointProject: '750px',
  //adminBase: 'http://admin.bbdarch.localhost',
  //adminBase: 'http://admin-bbdarch.zirkusdigitalo.de'
  adminBase: 'https://admin.bbdarch.de'
};