import React from 'react';
import { NavLink } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components';
import t from '../lang';
import config from '../config';

const Table = styled.div`
  border-top: 1px solid black;
`;

const Row = styled.div`
  border-bottom: 1px solid black;
  display: flex;
`;

const ProjectLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;

    & > * {
      background-color: #eeeeee;
    }
  }
`;

const Col = styled.span`
  display: inline-block;
  padding: 5px 0;
  flex: 7;
  
  &:first-child {
    display: none;

    @media only screen and (min-width: ${config.breakpointOffice}) {
      flex: 1;
      display: block;
    }
  }
`;

const PublicationsPage = (props) => {
  const data = props.data || [];
  
  if (data.length > 0) {
    return (
      <Sidebar page={props.location.pathname.split('/')[2]}>
        <Table>
          {data.map((item, index) => {
            let title = t(item, 'title');
            title += item.publisher ? ` / ${t(item, 'publisher')}` : '';
            title += item.isbn ? ` / ${item.isbn}` : '';

            if (typeof item.project_link === 'object') {
              return (
                <ProjectLink key={index} to={{
                  pathname: `/project/${item.project_link.display}`,
                  state: { from: '/office/references/publications' }
                }}>
                  <Row>
                    <Col>{t(item, 'year')}</Col>
                    <Col>{title}</Col>
                  </Row>
                </ProjectLink>
              );  
            } else {
              return (
                <Row key={index}>
                  <Col>{t(item, 'year')}</Col>
                  <Col>{title}</Col>
                </Row>
              );  
            }
          })}
        </Table>
      </Sidebar>
    );
  }

  return null;
}

export default PublicationsPage;