import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import t from '../lang';
import config from '../config';

const Projects = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(20rem, 1fr));
`;

const ProjectsItem = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const ProjectsImage = styled.img`
  width: 100%;
  margin-bottom: 5px;
`;

const ProjectsText = styled.div`
  margin-bottom: 2.0rem;
`;

const UrbanDesignPage = (props) => {
  const data = props.data || [];
  const selection = data; //data.filter(item => item.usage === 'urban-design');
  
  if (selection.length > 0) {
    return (
      <Projects>
        {selection.map((item, index) => {
          let date = item.date_to !== '' ? `${item.date_from}-${item.date_to}` : `${item.date_from}`;

          return (
            <ProjectsItem key={index} to={{
              pathname: `/urban-design/${item.name_slug}`,
              state: { from: '/urban-design/selection' }
            }}>
              <ProjectsImage src={`${config.adminBase}/${item.image.path}`} alt={t(item, 'name')} onContextMenu={(e) => {e.preventDefault(); return false;}}/>
                <h2>{t(item, 'name')}</h2>
              <ProjectsText>{date}</ProjectsText>
            </ProjectsItem>
          );
        })}
      </Projects>
    );
  }
  
  return null;
};

export default UrbanDesignPage;