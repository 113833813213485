import React from 'react';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components';
import t from '../lang';
import config from '../config';

const ContentImage = styled.img`
  margin-bottom: 1rem;
`;

const CareerPage = (props) => {
  const data = props.data || {};

  if (Object.keys(data).length > 0) {
    return (
      <div>
        <Sidebar page={props.location.pathname.split('/')[2]}>
          <ContentImage src={`${config.adminBase}/${data.image.path}`} alt="" />
          <div dangerouslySetInnerHTML={{ __html: t(data, 'text') }}></div>
        </Sidebar>
      </div>
    );
  }

  return null;
};

export default CareerPage;