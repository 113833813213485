import React from 'react';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components';
import t from '../lang';
import config from '../config';

const Members = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(10rem, 1fr));
  margin-bottom: 5rem;
`;

const MembersItem = styled.div`
  margin-bottom: 15px;
`;

const MemberImage = styled.img`
  margin-bottom: 0.5rem;
`;

const TeamPage = (props) => {
  const data = props.data || {};

  if (Object.keys(data).length > 0) {
    return (
      <div>
        <Sidebar page={props.location.pathname.split('/')[2]}>
          <Members>
            {data.members.map(item => {
              return (
                <MembersItem>
                  <MemberImage src={`${config.adminBase}/${item.value.image.path}`} alt={t(item.value, 'name')}/>
                  <div>{t(item.value, 'name')}</div>
                  <div>{t(item.value, 'position')}</div>
                </MembersItem>
              );
            })}
          </Members>
          

          <div dangerouslySetInnerHTML={{ __html: t(data, 'text') }}></div>
        </Sidebar>
      </div>
    );
  }

  return null;
};

export default TeamPage;